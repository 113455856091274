<template>
  <div class="myMember">
    <div class="title">{{type === 'vip' ? '权益介绍' : type === 'guarantee' ? '信任与保障': type === 'user_agreement' ? '用户协议' : ''}}：</div>
    <div v-if="type === 'vip'" ref="contentRef" class="content" v-html="articleDetail.vip"></div>
    <div v-else-if="type === 'guarantee'" ref="contentRef" class="content" v-html="articleDetail.guarantee"></div>
    <div v-else ref="contentRef" class="content" v-html="articleDetail.user_agreement"></div>
  </div>
</template>

<script>
import {system} from '@/api/common/public'
export default {
  name: 'myMember',
  data() {
    return {
      articleDetail: {},
      type: ''
    }
  },
  mounted() {
    // this.initHeight()
    this.type = localStorage.getItem('vipMenu')
    // console.log(this.$route.query.type)
    this.systemModel()
  },
  methods: {
    systemModel() {
      const data = {
        key: 'vip,phone,guarantee,user_agreement'
      }
      system(data).then(res => {
        this.articleDetail = res.data
      })
    }
    // initHeight() {
    //   this.$nextTick(() => {
    //     const height = this.$refs.contentRef.offsetHeight
    //     console.log(height)
    //     this.showHeight = `${height}px`
    //     console.log(this.showHeight)
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
  .myMember{
    padding: 32px;
    background: #fff;
    margin-top: 12px;
    min-height: 439px;
    .detail{
      margin-top: 25px;
      font-size: 14px;
    }
  }
</style>
